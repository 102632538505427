// Solution SCSS

.tags-section {
    padding: 80px 0;
    @include tablet {
        padding: 90px 0 0;
    }
    &.extra-spacing {
        margin-top: 75px;
        @include tablet {
            margin-top: 0;
        }
    }
    & .h2-sm {
        font-size: 60px;
        line-height: 72px;
        @include tablet {
            font-size: 36px;
            line-height: normal;
        }
    }
    & .block {
        display: flex;
        flex-direction: column;
        gap: 64px;
        text-align: center;
        align-items: center;
        @include tablet {
            gap: 32px;
        }
        & .tags {
            padding: 0 130px;
            @include tablet {
                padding: 0;
            }
        }
    }
}

.tags {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
    @include tablet {
      gap: 8px;
    }
    & li {
        font-size: 18px;
        line-height: 28px;
        font-weight: 500;
        padding: 12px 24px;
        text-align: center;
        border-radius: 30px;
        background-color: rgba($primary-700, 10%);
        border: 1px solid rgba($primary-700, 20%);;
        color: $primary-700;
        @include tablet {
            padding: 6px 12px;
            font-size: 14px;
            line-height: 14px;
        }
    }
}
