// Forms SCSS
.email-form {
    display: flex;
    width: 100%;
    padding: 8px 8px 8px 24px;
    align-items: center;
    gap: 10px;
    border-radius: 1000px;
    border: 1px solid $gray-100;
    background: $white;
    & input {
        flex: 1;
        padding: 12px 0;
        font-size: 18px;
        line-height: 28px;
        font-weight: 500;
        border: none;
        width: inherit;
    }
}

.form-unit {
    & small {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        margin-top: 3px;
        opacity: 0;
        visibility: hidden;
        transition: $btn-transition;
        @include mobile {
            font-size: 12px;
            line-height: 16px;
        }
    }
    &.error {
        & .input-field {}
    }
    &.error small {
        opacity: 1;
        visibility: visible;
    }
    & textarea {
        height: 159px;
        resize: none;
    }
    & .input-field {
        width: 100%;
        border: solid 1px rgba($white, 0.2);
        background: rgba(24, 36, 55, 0.6);
        border-radius: 16px;
        padding: 27px 38px 26px;
        font-size: 18px;
        line-height: 27px;
        transition: $btn-transition;
        color: $white;
        @include mobile {
            padding: 12px 32px;
            border-radius: 8px;
            font-size: 16px;
            line-height: 24px;
        }
        &::placeholder {
            opacity: 1;
        }
        &:-ms-input-placeholder {}
        &::-ms-input-placeholder {}
        &:focus {}
    }
}

.alert {
    display: none;
    animation: fadeIn 0.3s ease;
    flex-direction: column;
    position: absolute;
    @include mobile {
        position: static;
    }
    & .message {
        display: flex;
        align-items: center;
        column-gap: 4px;
        margin-bottom: 2px;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
    }
    & p {
        white-space: nowrap;
        line-height: 27px;
        @include mobile {
            white-space: initial;
        }
    }
}

.alert-processing {
    & .message {
        color: $white;
    }
}

.alert-success {
    & .message {}
}

.alert-danger {
    & .message {}
}