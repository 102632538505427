// Footer SCSS

.footer-section {
    background-color: $primary-900;
    color: $white;
    & hr {
        border: none;
        border-bottom: 1px solid $primary-900;
        margin: 0;
    }
    & .top {
        display: flex;
        justify-content: space-between;
        padding: 64px 0;
        @include tablet {
            flex-direction: column;
            align-items: flex-start;
            gap: 48px;
        }
        @include mobile {
            align-items: center;
        }
        & .logo {
            margin-right: 160px;
            @include laptop {
                margin-right: 80px;
            }
            @include tablet {
                margin-right: 0;
            }
        }
        & .button {
            margin-left: 48px;
            @include tablet {
                margin-left: 0;
            }
        }
        & .links {
            flex: 1;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 16px;
            @include tablet {
                width: 100%;
            }
            @include mobile {
                display: flex;
                align-items: center;
                flex-direction: column;
                gap: 32px;
            }
            & .item {
                display: flex;
                flex-direction: column;
                gap: 16px;
                @include mobile {
                    align-items: center;
                    text-align: center;
                }
                & p {
                    color: $gray-400;
                    font-weight: 500;
                    font-size: 14px;
                    font-style: normal;
                    line-height: 20px;
                }
                & ul {
                    display: flex;
                    flex-direction: column;
                    gap: 12px;
                    & a {
                        font-size: 16px;
                        color: $white;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 24px;
                    }
                }
            }
        }
    }
    & .bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 24px 0;
        & p {
            color: $white;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            @include tablet {
                font-size: 12px;
            }
        }
        & ul {
            display: flex;
            align-items: center;
            gap: 24px;
        }
    }
}
