// Swiper CSS {
.swiper-pagination {
    bottom: -30px;
    position: absolute;
    display: flex;
    align-items: center;
    column-gap: 6px;
    left: 50%;
    transform: translateX(-50%);
    @include mobile {
        bottom: -21px;
    }
    & .swiper-pagination-bullet {
        width: 8px;
        height: 8px;
        border: 1px solid $gray-700;
        background-color: $white;
        border-radius: 8px;
        transition: $link-transition;
        &.swiper-pagination-bullet-active {
            background-color: $gray-700;
        }
    }
}