// Helper SCSS
@mixin keyframes($name) {
    @keyframes #{$name} {
        @content;
    }
}

// Media
@mixin mobile {
    @media (max-width: $mobile-width) {
        @content;
    }
}

@mixin tablet {
    @media (max-width: $tablet-width) {
        @content;
    }
}



@mixin laptop {
    @media (max-width: $laptop-width) {
        @content;
    }
}

@mixin desktop {
    @media (max-width: $desktop-width) {
        @content;
    }
}

@mixin min-laptop {
    @media (min-width: $tablet-width) {
        @content;
    }
}

// Placehoders
%bg-image {
    width: 100%;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

%object-cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

%absolute-center {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    justify-content: center;
    display: flex;
    align-items: center;
}

%absolute-content {
    position: absolute;
    content: '';
}