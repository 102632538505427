// Typography SCSS
body {
    font-family: $font-Inter;
    color: $gray-700;
    background-color: $gray-25;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

a {
    transition: $link-transition;
    text-decoration: none;
    color: $link;
    &:hover {
        color: $link;
    }
}

b,
strong {
    font-weight: 700;
}

h1,
h1 {
    font-size: 72px;
    font-family: $font-Inter;
    font-style: normal;
    font-weight: 600;
    line-height: 90px;
    letter-spacing: -1.44px;
    color: $primary-900;
    @include tablet {
        font-size: 48px;
        line-height: 60px;
        letter-spacing: -0.96px;
    }
}



.h2,
h2 {
    font-size: 64px;
    font-family: $font-Inter;
    font-style: normal;
    font-weight: 600;
    line-height: 77px;
    letter-spacing: -1.28px;
    color: $primary-900;
    @include tablet {
        font-size: 36px;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.72px;
    }
}

.h3,
h3 {
    font-size: 48px;
    font-family: $font-Inter;
    font-style: normal;
    font-weight: 600;
    line-height: 60px;
    letter-spacing: -0.96px;
    color: $primary-900;
    @include tablet {
        font-size: 36px;
        letter-spacing: -0.72px;
        line-height: 48px;
    }
}

.h4,
h4 {
    font-size: 24px;
    font-family: $font-Inter;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    color: $primary-900;
}

.h5,
h5 {
    font-size: 20px;
    font-family: $font-Inter;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    color: $primary-900;
}

.h6,
h6 {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    color: $gray-700;
}


.p,
p {
    font-size: 16px;
    color: $gray-600;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

}

small, .small {
    font-size: 14px;
    color: $gray-600;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}