@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap");
* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

html,
body {
  min-height: 100%;
}

a:focus {
  outline: none;
}

input,
textarea {
  box-shadow: none;
  font-family: inherit;
}
input:focus,
textarea:focus {
  box-shadow: none;
  outline: none;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

img,
figure {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

svg {
  max-width: 100%;
}

iframe {
  width: 100%;
}

small {
  font-weight: inherit;
}

body {
  font-family: "Inter", sans-serif;
  color: #344054;
  background-color: #FCFCFD;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

a {
  transition: 200ms all cubic-bezier(0.49, 0.025, 0.49, 1);
  text-decoration: none;
  color: #5CADF8;
}
a:hover {
  color: #5CADF8;
}

b,
strong {
  font-weight: 700;
}

h1,
h1 {
  font-size: 72px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 90px;
  letter-spacing: -1.44px;
  color: #132043;
}
@media (max-width: 991px) {
  h1,
  h1 {
    font-size: 48px;
    line-height: 60px;
    letter-spacing: -0.96px;
  }
}

.h2,
h2 {
  font-size: 64px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 77px;
  letter-spacing: -1.28px;
  color: #132043;
}
@media (max-width: 991px) {
  .h2,
  h2 {
    font-size: 36px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.72px;
  }
}

.h3,
h3 {
  font-size: 48px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: -0.96px;
  color: #132043;
}
@media (max-width: 991px) {
  .h3,
  h3 {
    font-size: 36px;
    letter-spacing: -0.72px;
    line-height: 48px;
  }
}

.h4,
h4 {
  font-size: 24px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  color: #132043;
}

.h5,
h5 {
  font-size: 20px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  color: #132043;
}

.h6,
h6 {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  color: #344054;
}

.p,
p {
  font-size: 16px;
  color: #475467;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

small, .small {
  font-size: 14px;
  color: #475467;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
.container {
  max-width: 1360px;
  padding-left: 40px;
  padding-right: 40px;
  margin: 0 auto;
}
@media (max-width: 991px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.container-lg {
  max-width: 1440px;
  padding-left: 32px;
  padding-right: 32px;
  margin: 0 auto;
}
@media (max-width: 991px) {
  .container-lg {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.container-xl {
  max-width: 1440px;
  padding-left: 0;
  padding-right: 0;
  margin: 0 auto;
}

.collapse {
  overflow: hidden;
  transition: height 300ms cubic-bezier(0.49, 0.025, 0.49, 1);
}
.collapse:not(.active) {
  height: 0;
}
@media (min-width: 991px) {
  .collapse.sub-menu-wrapper {
    height: auto !important;
  }
}

[data-collapse] .collapse-arrow,
[data-toggler] .collapse-arrow {
  transition: 300ms all cubic-bezier(0.49, 0.025, 0.49, 1);
}
[data-collapse] .collapse-arrow img,
[data-toggler] .collapse-arrow img {
  transition: 300ms all cubic-bezier(0.49, 0.025, 0.49, 1);
}
[data-collapse].active .collapse-arrow img,
[data-toggler].active .collapse-arrow img {
  transform: rotate(-180deg);
}

.hide-scroll {
  overflow: hidden;
  height: 100vh;
}

.btn {
  cursor: pointer;
  font-family: "Inter", sans-serif;
  border: none;
  transition: 300ms background-color cubic-bezier(0.49, 0.025, 0.49, 1), 300ms color cubic-bezier(0.49, 0.025, 0.49, 1), 300ms border-color cubic-bezier(0.49, 0.025, 0.49, 1), 300ms opacity cubic-bezier(0.49, 0.025, 0.49, 1), 300ms box-shadow cubic-bezier(0.49, 0.025, 0.49, 1), 300ms stroke cubic-bezier(0.49, 0.025, 0.49, 1), 300ms fill cubic-bezier(0.49, 0.025, 0.49, 1);
  display: flex;
  align-items: center;
  white-space: nowrap;
  justify-content: center;
  flex-shrink: 0;
}
.btn svg {
  flex-shrink: 0;
}
.btn svg * {
  transition: 300ms background-color cubic-bezier(0.49, 0.025, 0.49, 1), 300ms color cubic-bezier(0.49, 0.025, 0.49, 1), 300ms border-color cubic-bezier(0.49, 0.025, 0.49, 1), 300ms opacity cubic-bezier(0.49, 0.025, 0.49, 1), 300ms box-shadow cubic-bezier(0.49, 0.025, 0.49, 1), 300ms stroke cubic-bezier(0.49, 0.025, 0.49, 1), 300ms fill cubic-bezier(0.49, 0.025, 0.49, 1);
}
.btn:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.btn-xs {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 8px 24px;
  border-radius: 100px;
  gap: 8px;
}

.btn-sm {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  padding: 8px 24px;
  border-radius: 100px;
}

.btn-md {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  padding: 12px 40px;
  border-radius: 100px;
  column-gap: 8px;
}

.btn-primary {
  background-color: #5CADF8;
  color: #FFFFFF;
}
.btn-primary:hover {
  color: #FFFFFF;
}
.btn-primary:active {
  transform: scale(0.95);
}

.btn-light {
  background-color: #FFFFFF;
  color: #5CADF8;
  box-shadow: 0 26.8325042725px 29.4054851532px 0 rgba(0, 0, 0, 0.02), 0 89.0053405762px 97.5401000977px 0 rgba(0, 0, 0, 0.03);
}
.btn-light:hover {
  color: #5CADF8;
}
.btn-light:active {
  transform: scale(0.95);
}

.btn-link {
  column-gap: 8px;
  color: #5CADF8;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
}
.btn-link:hover {
  color: #5CADF8;
}

.btn-link-light {
  column-gap: 8px;
  color: #5CADF8;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}
.btn-link-light:hover {
  color: #5CADF8;
}

.checkbox-unit input {
  display: none;
}
.checkbox-unit label {
  display: inline-flex;
  cursor: pointer;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #FFFFFF;
  text-align: left;
}
@media (max-width: 767px) {
  .checkbox-unit label {
    font-size: 16px;
  }
}
.checkbox-unit label span {
  border: 1px solid #FFFFFF;
  border-radius: 3px;
  width: 20px;
  height: 20px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  margin-top: 2px;
  position: relative;
  background-color: transparent;
}
.checkbox-unit label span svg {
  transform: scale(0);
}
.checkbox-unit input:checked + label svg {
  transform: scale(1);
}

.email-form {
  display: flex;
  width: 100%;
  padding: 8px 8px 8px 24px;
  align-items: center;
  gap: 10px;
  border-radius: 1000px;
  border: 1px solid #F2F4F7;
  background: #FFFFFF;
}
.email-form input {
  flex: 1;
  padding: 12px 0;
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  border: none;
  width: inherit;
}

.form-unit small {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin-top: 3px;
  opacity: 0;
  visibility: hidden;
  transition: 300ms background-color cubic-bezier(0.49, 0.025, 0.49, 1), 300ms color cubic-bezier(0.49, 0.025, 0.49, 1), 300ms border-color cubic-bezier(0.49, 0.025, 0.49, 1), 300ms opacity cubic-bezier(0.49, 0.025, 0.49, 1), 300ms box-shadow cubic-bezier(0.49, 0.025, 0.49, 1), 300ms stroke cubic-bezier(0.49, 0.025, 0.49, 1), 300ms fill cubic-bezier(0.49, 0.025, 0.49, 1);
}
@media (max-width: 767px) {
  .form-unit small {
    font-size: 12px;
    line-height: 16px;
  }
}
.form-unit.error small {
  opacity: 1;
  visibility: visible;
}
.form-unit textarea {
  height: 159px;
  resize: none;
}
.form-unit .input-field {
  width: 100%;
  border: solid 1px rgba(255, 255, 255, 0.2);
  background: rgba(24, 36, 55, 0.6);
  border-radius: 16px;
  padding: 27px 38px 26px;
  font-size: 18px;
  line-height: 27px;
  transition: 300ms background-color cubic-bezier(0.49, 0.025, 0.49, 1), 300ms color cubic-bezier(0.49, 0.025, 0.49, 1), 300ms border-color cubic-bezier(0.49, 0.025, 0.49, 1), 300ms opacity cubic-bezier(0.49, 0.025, 0.49, 1), 300ms box-shadow cubic-bezier(0.49, 0.025, 0.49, 1), 300ms stroke cubic-bezier(0.49, 0.025, 0.49, 1), 300ms fill cubic-bezier(0.49, 0.025, 0.49, 1);
  color: #FFFFFF;
}
@media (max-width: 767px) {
  .form-unit .input-field {
    padding: 12px 32px;
    border-radius: 8px;
    font-size: 16px;
    line-height: 24px;
  }
}
.form-unit .input-field::placeholder {
  opacity: 1;
}
.alert {
  display: none;
  animation: fadeIn 0.3s ease;
  flex-direction: column;
  position: absolute;
}
@media (max-width: 767px) {
  .alert {
    position: static;
  }
}
.alert .message {
  display: flex;
  align-items: center;
  column-gap: 4px;
  margin-bottom: 2px;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
}
.alert p {
  white-space: nowrap;
  line-height: 27px;
}
@media (max-width: 767px) {
  .alert p {
    white-space: initial;
  }
}

.alert-processing .message {
  color: #FFFFFF;
}

.swiper-pagination {
  bottom: -30px;
  position: absolute;
  display: flex;
  align-items: center;
  column-gap: 6px;
  left: 50%;
  transform: translateX(-50%);
}
@media (max-width: 767px) {
  .swiper-pagination {
    bottom: -21px;
  }
}
.swiper-pagination .swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  border: 1px solid #344054;
  background-color: #FFFFFF;
  border-radius: 8px;
  transition: 200ms all cubic-bezier(0.49, 0.025, 0.49, 1);
}
.swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #344054;
}

.header-section {
  position: fixed;
  top: 16px;
  z-index: 90;
  left: 0;
  right: 0;
}
@media (max-width: 991px) {
  .header-section {
    top: 24px;
  }
}
.header-section .header-block {
  padding: 7px 8px 7px 24px;
  border-radius: 1000px;
  border: 1px solid #F2F4F7;
  box-shadow: 0 3.3060238361px 3.6230399609px 0 rgba(0, 0, 0, 0.01), 0 9.1407327652px 10.017241478px 0 rgba(0, 0, 0, 0.01), 0 22.0073623657px 24.1176586151px 0 rgba(0, 0, 0, 0.02), 0 73px 80px 0 rgba(0, 0, 0, 0.03);
  width: 100%;
  background-color: #FFFFFF;
  border-radius: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 991px) {
  .header-section .header-block {
    padding: 8px 30px 8px 24px;
  }
}
@media (max-width: 991px) {
  .header-section .header-block .btn-primary {
    display: none;
  }
}
.header-section .header-block .logo-block img {
  width: 139px;
}
@media (max-width: 991px) {
  .header-section .header-block .logo-block img {
    width: 106px;
  }
}
.header-section .header-block .toggler {
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 10;
  position: relative;
  display: none;
  outline: none;
  width: 17px;
  height: 32px;
  transition: 0.6s all ease;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
@media (max-width: 991px) {
  .header-section .header-block .toggler {
    display: block;
  }
}
.header-section .header-block .toggler span {
  border-radius: 2px;
  width: 17px;
  height: 1.5px;
  display: block;
  background-color: #132043;
  position: relative;
  transition: all 0.1s;
}
.header-section .header-block .toggler span::after, .header-section .header-block .toggler span::before {
  border-radius: 2px;
  transition: all 0.3s;
  position: absolute;
  left: 0;
  content: "";
  height: 1.5px;
  background-color: #132043;
}
.header-section .header-block .toggler span::before {
  width: 17px;
  top: 6px;
}
.header-section .header-block .toggler span::after {
  width: 17px;
  bottom: 6px;
}
.header-section .header-block .menu-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  transition: all 0.6s ease;
}
@media (max-width: 991px) {
  .header-section .header-block .menu-wrapper {
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 20px;
    right: 20px;
    padding: 45px 18px 24px;
    box-shadow: 0 3.3060238361px 3.6230399609px 0 rgba(0, 0, 0, 0.01), 0 9.1407327652px 10.017241478px 0 rgba(0, 0, 0, 0.01), 0 22.0073623657px 24.1176586151px 0 rgba(0, 0, 0, 0.02), 0 73px 80px 0 rgba(0, 0, 0, 0.03);
    visibility: hidden;
    opacity: 0;
    transform: translateY(0px);
    transform-origin: top;
    transition: 1s all cubic-bezier(0.08, 1, 0.07, 1);
    background-color: #FFFFFF;
    justify-content: center;
    overflow: hidden;
    align-items: flex-start;
    z-index: -1;
    border-radius: 0 0 40px 40px;
  }
}
@media (max-width: 991px) {
  .header-section .header-block .menu-wrapper .header-wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
}
.header-section .header-block .menu-wrapper .header-wrapper .social-menu {
  display: none;
}
@media (max-width: 991px) {
  .header-section .header-block .menu-wrapper .header-wrapper .social-menu {
    display: flex;
    align-items: center;
    gap: 20px;
    align-self: center;
    margin-top: 40px;
  }
}
.header-section .header-block .menu-wrapper .header-wrapper .social-menu li a {
  display: flex;
}
.header-section .header-block .menu-wrapper .header-wrapper .menu {
  display: flex;
  align-items: center;
  gap: 16px;
  position: relative;
}
@media (max-width: 991px) {
  .header-section .header-block .menu-wrapper .header-wrapper .menu {
    flex-direction: column;
    align-items: flex-start;
  }
}
.header-section .header-block .menu-wrapper .header-wrapper .menu > li:hover .sub-menu-wrapper {
  opacity: 1;
  visibility: visible;
  top: 100%;
}
.header-section .header-block .menu-wrapper .header-wrapper .menu > li:first-child .sub-menu-wrapper .sub-menu::before {
  left: 267px;
}
.header-section .header-block .menu-wrapper .header-wrapper .menu > li:nth-child(2) .sub-menu-wrapper .sub-menu::before {
  left: 436px;
}
.header-section .header-block .menu-wrapper .header-wrapper .menu > li > a {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #344054;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  padding: 8px 16px;
}
.header-section .header-block .menu-wrapper .header-wrapper .menu > li > a img {
  position: relative;
  top: 1px;
}
.header-section .header-block.active .toggler span {
  background-color: transparent;
}
.header-section .header-block.active .toggler span::before {
  background-color: #132043;
  width: 17px;
  transform: translateY(-6px) translateX(0) rotate(-45deg);
}
.header-section .header-block.active .toggler span::after {
  background-color: #132043;
  width: 17px;
  transform: translateY(6px) translateX(0) rotate(45deg);
}
@media (max-width: 991px) {
  .header-section .header-block.active .menu-wrapper {
    visibility: visible;
    opacity: 1;
    transform: translateY(30px);
  }
}

.menu > li:nth-child(2) .sub-menu-wrapper .sub-menu .grids {
  grid-template-columns: repeat(2, 1fr);
}
@media (max-width: 991px) {
  .menu > li:nth-child(2) .sub-menu-wrapper .sub-menu .grids {
    grid-template-columns: 1fr;
    gap: 0;
  }
}
.menu > li .sub-menu-wrapper {
  display: flex;
  width: 884px;
  position: absolute;
  background-color: transparent;
  top: 200%;
  padding-top: 28px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  visibility: hidden;
  transition: 300ms all cubic-bezier(0.49, 0.025, 0.49, 1);
}
@media (max-width: 991px) {
  .menu > li .sub-menu-wrapper {
    opacity: 1;
    visibility: visible;
    position: static;
    width: 100%;
    padding-top: 0;
    transform: none;
  }
}
.menu > li .sub-menu-wrapper .sub-menu {
  width: 100%;
  background-color: #FFFFFF;
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  border-radius: 24px;
  border-radius: 24px;
  box-shadow: 0 3.3060238361px 3.6230399609px 0 rgba(0, 0, 0, 0.01), 0 9.1407327652px 10.017241478px 0 rgba(0, 0, 0, 0.01), 0 22.0073623657px 24.1176586151px 0 rgba(0, 0, 0, 0.02), 0 73px 80px 0 rgba(0, 0, 0, 0.03);
  position: relative;
}
@media (max-width: 991px) {
  .menu > li .sub-menu-wrapper .sub-menu {
    box-shadow: none;
    padding: 0;
  }
}
.menu > li .sub-menu-wrapper .sub-menu::before {
  position: absolute;
  width: 26px;
  height: 26px;
  transform: rotate(45deg);
  border-radius: 4px;
  background-color: #FFFFFF;
  content: "";
  top: -8px;
  left: 267px;
}
@media (max-width: 991px) {
  .menu > li .sub-menu-wrapper .sub-menu::before {
    display: none;
  }
}
@media (max-width: 991px) {
  .menu > li .sub-menu-wrapper .sub-menu .button {
    display: none;
  }
}
.menu > li .sub-menu-wrapper .sub-menu .grids {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  width: 100%;
}
@media (max-width: 991px) {
  .menu > li .sub-menu-wrapper .sub-menu .grids {
    grid-template-columns: 139px;
    gap: 0;
  }
}
.menu > li .sub-menu-wrapper .sub-menu .grids li > a {
  display: flex;
  padding: 24px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  color: #344054;
  border-radius: 8px;
  transition: 0.3s ease all;
  white-space: nowrap;
}
.menu > li .sub-menu-wrapper .sub-menu .grids li > a:hover {
  background-color: #F2F7FA;
}
@media (max-width: 991px) {
  .menu > li .sub-menu-wrapper .sub-menu .grids li > a {
    padding: 8px 16px 8px 35px;
    color: rgba(52, 64, 84, 0.6);
    font-size: 16px;
    font-weight: normal;
    line-height: 20px;
  }
}
@media (max-width: 991px) {
  .menu > li .sub-menu-wrapper .sub-menu .grids li > a img {
    display: none;
  }
}

.footer-section {
  background-color: #132043;
  color: #FFFFFF;
}
.footer-section hr {
  border: none;
  border-bottom: 1px solid #132043;
  margin: 0;
}
.footer-section .top {
  display: flex;
  justify-content: space-between;
  padding: 64px 0;
}
@media (max-width: 991px) {
  .footer-section .top {
    flex-direction: column;
    align-items: flex-start;
    gap: 48px;
  }
}
@media (max-width: 767px) {
  .footer-section .top {
    align-items: center;
  }
}
.footer-section .top .logo {
  margin-right: 160px;
}
@media (max-width: 1199px) {
  .footer-section .top .logo {
    margin-right: 80px;
  }
}
@media (max-width: 991px) {
  .footer-section .top .logo {
    margin-right: 0;
  }
}
.footer-section .top .button {
  margin-left: 48px;
}
@media (max-width: 991px) {
  .footer-section .top .button {
    margin-left: 0;
  }
}
.footer-section .top .links {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
}
@media (max-width: 991px) {
  .footer-section .top .links {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .footer-section .top .links {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 32px;
  }
}
.footer-section .top .links .item {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
@media (max-width: 767px) {
  .footer-section .top .links .item {
    align-items: center;
    text-align: center;
  }
}
.footer-section .top .links .item p {
  color: #98A2B3;
  font-weight: 500;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}
.footer-section .top .links .item ul {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.footer-section .top .links .item ul a {
  font-size: 16px;
  color: #FFFFFF;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.footer-section .bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0;
}
.footer-section .bottom p {
  color: #FFFFFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
@media (max-width: 991px) {
  .footer-section .bottom p {
    font-size: 12px;
  }
}
.footer-section .bottom ul {
  display: flex;
  align-items: center;
  gap: 24px;
}

.heroHome-section {
  background-color: rgba(92, 173, 248, 0.3);
  border-radius: 0 0 40px 40px;
  z-index: 10;
}
.heroHome-section .people {
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1319px;
  height: 703px;
  margin-top: 15px;
}
@media (max-width: 991px) {
  .heroHome-section .people {
    width: 341px;
    height: 640px;
    margin-top: 35px;
  }
}
.heroHome-section .curves {
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}
.heroHome-section .curves::after, .heroHome-section .curves::before {
  content: "";
  position: absolute;
  border-radius: 50%;
}
.heroHome-section .curves::before {
  width: 2074px;
  height: 2074px;
  background-color: rgba(255, 255, 255, 0.14);
  left: 50%;
  transform: translateX(-50%);
  margin-left: -629px;
  top: 376px;
}
@media (max-width: 991px) {
  .heroHome-section .curves::before {
    width: 754px;
    height: 754px;
    margin-left: -79px;
    top: 453px;
  }
}
.heroHome-section .curves::after {
  width: 2074px;
  height: 2074px;
  background-color: rgba(255, 255, 255, 0.2);
  right: 50%;
  transform: translateX(50%);
  margin-right: -407px;
  top: 753px;
}
@media (max-width: 991px) {
  .heroHome-section .curves::after {
    width: 903px;
    height: 903px;
    margin-right: -277px;
    top: 383px;
  }
}
.heroHome-section .block {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 800px;
  justify-content: center;
  padding-top: 84px;
}
@media (max-width: 991px) {
  .heroHome-section .block {
    height: 741px;
    padding-top: 44px;
  }
}
.heroHome-section .block .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 646px;
  width: 100%;
}
@media (max-width: 991px) {
  .heroHome-section .block .content {
    max-width: 345px;
  }
}
.heroHome-section .block .content h1 {
  margin-bottom: 24px;
}
@media (max-width: 991px) {
  .heroHome-section .block .content h1 {
    margin-bottom: 12px;
  }
}
.heroHome-section .block .content h6 {
  margin-bottom: 48px;
}
@media (max-width: 991px) {
  .heroHome-section .block .content h6 {
    margin-bottom: 16px;
  }
}

.unlock-section {
  padding: 260px 0 128px;
}
@media (max-width: 1199px) {
  .unlock-section {
    padding: 200px 0 90px;
  }
}
@media (max-width: 991px) {
  .unlock-section {
    padding: 106px 0 45px;
  }
}
.unlock-section .block {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 16px;
}
.unlock-section .block .h3 {
  max-width: 580px;
}
.unlock-section .block .h4 {
  max-width: 790px;
  font-weight: 400;
  color: #475467;
}
@media (max-width: 991px) {
  .unlock-section .block .h4 {
    font-size: 16px;
    line-height: 24px;
  }
}

.why-section {
  padding: 120px 0;
}
@media (max-width: 1199px) {
  .why-section {
    padding: 80px 0;
  }
}
@media (max-width: 991px) {
  .why-section {
    padding: 45px 0;
  }
}
.why-section .block {
  display: flex;
  gap: 32px;
  border-radius: 24px;
  background-color: #FFFFFF;
  justify-content: space-between;
  overflow: hidden;
  box-shadow: 0 4.0308737755px 4.4173960686px 0 rgba(0, 0, 0, 0.01), 0 11.1448497772px 12.2135343552px 0 rgba(0, 0, 0, 0.01), 0 26.8325042725px 29.4054851532px 0 rgba(0, 0, 0, 0.02), 0 89.0053405762px 97.5401000977px 0 rgba(0, 0, 0, 0.03);
}
@media (max-width: 991px) {
  .why-section .block {
    flex-direction: column;
    gap: 0;
  }
}
.why-section .block .image {
  background-color: #F2F7FA;
  flex-shrink: 0;
}
@media (max-width: 1199px) {
  .why-section .block .image {
    width: 50%;
  }
}
@media (max-width: 991px) {
  .why-section .block .image {
    width: 100%;
  }
}
@media (max-width: 1199px) {
  .why-section .block .image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
@media (max-width: 991px) {
  .why-section .block .image img {
    width: 100%;
    height: 571px;
    object-fit: cover;
  }
}
.why-section .block .content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 62px 32px;
}
@media (max-width: 1199px) {
  .why-section .block .content {
    flex-shrink: 0;
    width: 50%;
  }
}
@media (max-width: 991px) {
  .why-section .block .content {
    width: 100%;
    padding: 32px 24px 60px;
  }
}
.why-section .block .content .h3 {
  margin-bottom: 16px;
  max-width: 445px;
}
.why-section .block .content p {
  max-width: 563px;
  margin-bottom: 40px;
}
.why-section .block .content ul {
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
.why-section .block .content ul li {
  display: flex;
  align-items: center;
  column-gap: 10px;
  color: #132043;
  font-weight: 500;
}

.empowerment-section {
  padding: 120px 0;
  position: relative;
  z-index: 10;
}
@media (max-width: 1199px) {
  .empowerment-section {
    padding: 80px 0;
  }
}
@media (max-width: 991px) {
  .empowerment-section {
    padding: 45px 0;
  }
}
.empowerment-section::after {
  z-index: -10;
  width: 1336px;
  height: 1065px;
  background-image: url("../assets/images/svg/ring-large.svg");
  content: "";
  position: absolute;
  left: 50%;
  top: -65px;
  margin-left: 123px;
}
.empowerment-section .block {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.empowerment-section .block .h3 {
  max-width: 662px;
  text-align: center;
  margin-bottom: 16px;
}
.empowerment-section .block > p {
  max-width: 829px;
  text-align: center;
}
.empowerment-section .block .cards {
  margin-top: 64px;
  margin-bottom: 48px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  width: 100%;
}
@media (max-width: 991px) {
  .empowerment-section .block .cards {
    margin-top: 16px;
    margin-bottom: 40px;
    grid-template-columns: 1fr;
  }
}
.empowerment-section .block .cards li {
  box-shadow: 0 4.0308737755px 4.4173960686px 0 rgba(0, 0, 0, 0.01), 0 11.1448497772px 12.2135343552px 0 rgba(0, 0, 0, 0.01), 0 26.8325042725px 29.4054851532px 0 rgba(0, 0, 0, 0.02), 0 89.0053405762px 97.5401000977px 0 rgba(0, 0, 0, 0.03);
}
.empowerment-section .block .cards li p {
  max-width: 368px;
}

.discover-section {
  padding: 120px 0 0;
  position: relative;
  z-index: 0;
}
@media (max-width: 1199px) {
  .discover-section {
    padding: 80px 0 0;
  }
}
@media (max-width: 991px) {
  .discover-section {
    padding: 45px 0 0;
  }
}
.discover-section::after {
  z-index: -10;
  width: 1530px;
  height: 1530px;
  background-image: url("../assets/images/svg/ring-mid.svg");
  content: "";
  position: absolute;
  right: 50%;
  top: -341px;
  margin-right: -20px;
}
.discover-section .block {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.discover-section .block .top {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 580px;
  width: 100%;
  text-align: center;
  margin-bottom: 106px;
}
@media (max-width: 991px) {
  .discover-section .block .top {
    margin-bottom: 34px;
  }
}
.discover-section .block .top .h3 {
  margin-bottom: 16px;
}
.discover-section .block .top p {
  margin-bottom: 40px;
}
.discover-section .block .bottom {
  position: relative;
}
@media (max-width: 991px) {
  .discover-section .block .bottom {
    margin-bottom: -60px;
  }
}
@media (max-width: 767px) {
  .discover-section .block .bottom {
    align-self: flex-start;
  }
}
@media (max-width: 991px) {
  .discover-section .block .bottom img {
    border-radius: 12px;
  }
}
.discover-section .block .bottom::after {
  z-index: -10;
  width: 1020px;
  height: 1020px;
  content: "";
  position: absolute;
  left: 50%;
  top: 50px;
  transform: translateX(-50%);
  border-radius: 1020px;
  opacity: 0.6;
  background: #5CADF8;
  filter: blur(296.5116271973px);
}
.discover-section .block .bottom img {
  width: 1102px;
}
@media (max-width: 991px) {
  .discover-section .block .bottom img {
    width: 548px;
    max-width: none;
  }
}

.applyHome-section .block {
  display: flex;
  background-color: #132043;
  padding: 125px 80px;
  border-radius: 40px 40px 0 0;
  position: relative;
  overflow: hidden;
  z-index: 0;
}
@media (max-width: 991px) {
  .applyHome-section .block {
    padding: 120px 20px 64px;
  }
}
.applyHome-section .block .bg-rings {
  position: absolute;
  bottom: -203px;
  right: -610px;
  z-index: -10;
}
@media (max-width: 991px) {
  .applyHome-section .block .bg-rings {
    transform: scale(0.7);
  }
}
.applyHome-section .block .content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.applyHome-section .block .content h2 {
  color: #FFFFFF;
  max-width: 749px;
  margin-bottom: 16px;
}
@media (max-width: 991px) {
  .applyHome-section .block .content h2 {
    max-width: 335px;
  }
}
.applyHome-section .block .content h6 {
  color: #FCFCFD;
  max-width: 440px;
}
.applyHome-section .block .content .button {
  margin-top: 120px;
}
@media (max-width: 991px) {
  .applyHome-section .block .content .button {
    margin-top: 48px;
  }
}

.heroCentered-section {
  background-color: rgba(92, 173, 248, 0.3);
  padding: 200px 0 153px;
  border-radius: 0 0 40px 40px;
  z-index: 10;
}
@media (max-width: 1199px) {
  .heroCentered-section {
    padding: 174px 0 130px;
  }
}
@media (max-width: 991px) {
  .heroCentered-section {
    padding: 154px 0 118px;
  }
}
.heroCentered-section .curves {
  position: absolute;
  z-index: -1;
  top: 40px;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  text-align: center;
  opacity: 0.3;
  left: 50%;
  transform: translateX(-50%);
  width: 1158px;
}
@media (max-width: 991px) {
  .heroCentered-section .curves {
    width: 630px;
    top: 130px;
  }
}
.heroCentered-section .block {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.heroCentered-section .block .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 646px;
  width: 100%;
}
@media (max-width: 767px) {
  .heroCentered-section .block .content {
    max-width: 335px;
  }
}
.heroCentered-section .block .content h1 {
  margin-bottom: 24px;
}
@media (max-width: 991px) {
  .heroCentered-section .block .content h1 {
    margin-bottom: 12px;
  }
}
.heroCentered-section .block .button {
  margin-top: 48px;
  max-width: 500px;
  position: absolute;
  bottom: -26px;
  left: 50%;
  transform: translateX(-50%);
}
@media (max-width: 767px) {
  .heroCentered-section .block .button {
    max-width: 335px;
  }
}

.whyFeature-section {
  padding: 146px 0 120px;
}
@media (max-width: 1199px) {
  .whyFeature-section {
    padding: 100px 0;
  }
}
@media (max-width: 991px) {
  .whyFeature-section {
    padding: 90px 0;
  }
}
.whyFeature-section .block {
  z-index: 10;
  position: relative;
}
.whyFeature-section .block .curves {
  position: absolute;
  z-index: -1;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  justify-content: center;
  display: flex;
  align-items: center;
}
@media (max-width: 991px) {
  .whyFeature-section .block .curves {
    display: none;
  }
}
.whyFeature-section .block .cards {
  display: flex;
  column-gap: 32px;
  row-gap: 32px;
  flex-wrap: wrap;
}
@media (max-width: 767px) {
  .whyFeature-section .block .cards {
    flex-direction: column;
  }
}
.whyFeature-section .block .cards li {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #F2F7FA;
  padding: 24px;
  border-radius: 16px;
}
.whyFeature-section .block .cards li:nth-child(1) {
  width: calc(42% - 16px);
}
@media (max-width: 767px) {
  .whyFeature-section .block .cards li:nth-child(1) {
    width: 100%;
  }
}
.whyFeature-section .block .cards li:nth-child(2) {
  width: calc(58% - 16px);
}
@media (max-width: 767px) {
  .whyFeature-section .block .cards li:nth-child(2) {
    width: 100%;
  }
}
.whyFeature-section .block .cards li:nth-child(3) {
  width: calc(58% - 16px);
}
@media (max-width: 767px) {
  .whyFeature-section .block .cards li:nth-child(3) {
    width: 100%;
  }
}
.whyFeature-section .block .cards li:nth-child(4) {
  width: calc(42% - 16px);
}
@media (max-width: 767px) {
  .whyFeature-section .block .cards li:nth-child(4) {
    width: 100%;
  }
}
.whyFeature-section .block .cards li img {
  margin-bottom: 24px;
}
.whyFeature-section .block .cards li .h5 {
  margin-bottom: 8px;
}

.action-section {
  padding: 146px 0 120px;
}
@media (max-width: 1199px) {
  .action-section {
    padding: 100px 0;
  }
}
@media (max-width: 991px) {
  .action-section {
    padding: 90px 0;
  }
}
.action-section .block {
  z-index: 10;
  position: relative;
}
.action-section .block .curves {
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  justify-content: center;
  display: flex;
  align-items: center;
}
@media (max-width: 991px) {
  .action-section .block .curves {
    display: none;
  }
}
.action-section .block .cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 48px;
}
@media (max-width: 991px) {
  .action-section .block .cards {
    display: flex;
    flex-direction: column;
  }
}
.action-section .block .cards li {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.action-section .block .cards li img {
  margin-bottom: 24px;
}
.action-section .block .cards li .h5 {
  margin-bottom: 8px;
}

.carry-section {
  padding: 32px 0;
}
@media (max-width: 991px) {
  .carry-section {
    padding: 0;
  }
}
.carry-section.extra-spacing {
  margin-top: 208px;
}
@media (max-width: 1199px) {
  .carry-section.extra-spacing {
    margin-top: 140px;
  }
}
@media (max-width: 991px) {
  .carry-section.extra-spacing {
    margin-top: 120px;
  }
}
.carry-section .block {
  background-color: rgba(92, 173, 248, 0.3);
  border-radius: 40px;
  padding: 120px 80px;
  position: relative;
  z-index: 10;
  overflow: hidden;
}
@media (max-width: 1199px) {
  .carry-section .block {
    padding: 90px 80px;
  }
}
@media (max-width: 991px) {
  .carry-section .block {
    padding: 90px 20px;
  }
}
.carry-section .block .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 32px;
}
@media (max-width: 991px) {
  .carry-section .block .content {
    gap: 16px;
  }
}
.carry-section .block .content h2 {
  max-width: 852px;
}
.carry-section .block .content p {
  max-width: 578px;
}
.carry-section .block .card-bg {
  display: flex;
  position: absolute;
  z-index: -1;
  justify-content: center;
  align-items: flex-end;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
@media (max-width: 991px) {
  .carry-section .block .card-bg {
    padding: 0 30px;
    opacity: 0.7;
  }
}

.twoColumns-section {
  padding: 120px 0;
}
@media (max-width: 1199px) {
  .twoColumns-section {
    padding: 90px 0;
  }
}
.twoColumns-section.py-0 {
  padding: 0;
}
.twoColumns-section.pt-0 {
  padding-top: 0;
}
.twoColumns-section .block {
  display: flex;
  flex-direction: column;
  gap: 64px;
}
@media (max-width: 767px) {
  .twoColumns-section .block {
    gap: 90px;
  }
}
.twoColumns-section .block .row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 72px;
  align-items: center;
}
@media (max-width: 991px) {
  .twoColumns-section .block .row {
    gap: 48px;
  }
}
@media (max-width: 767px) {
  .twoColumns-section .block .row {
    display: flex;
    flex-direction: column;
  }
}
.twoColumns-section .block .row:nth-child(2n) {
  flex-direction: column-reverse;
}
.twoColumns-section .block .row .content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.twoColumns-section .block .row .content h3 {
  margin-bottom: 16px;
}
.twoColumns-section .block .row .content .button {
  margin-top: 40px;
}
.twoColumns-section .block .row .content ul {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.twoColumns-section .block .row .content ul li {
  display: flex;
  align-items: center;
  gap: 16px;
}
.twoColumns-section .block .row .image {
  flex-shrink: 0;
  border-radius: 16px;
  display: flex;
  align-items: center;
}
@media (max-width: 767px) {
  .twoColumns-section .block .row .image {
    height: 600px;
    background-color: #F2F7FA;
    margin: 0 -20px;
    border-radius: 0;
  }
}
@media (max-width: 767px) {
  .twoColumns-section .block .row .image img {
    width: 100%;
    max-width: 375px;
    object-fit: contain;
  }
}

.apply-section .block {
  display: flex;
  background-color: #132043;
  padding: 157px 0 0 80px;
  border-radius: 40px 40px 0 0;
  position: relative;
  overflow: hidden;
  z-index: 0;
  justify-content: space-between;
  column-gap: 62px;
}
@media (max-width: 1199px) {
  .apply-section .block {
    padding: 120px 0 0 80px;
    flex-direction: column;
    row-gap: 62px;
  }
}
@media (max-width: 991px) {
  .apply-section .block {
    padding: 120px 20px 0;
    row-gap: 34px;
  }
}
.apply-section .block .bg-cards {
  display: flex;
}
@media (max-width: 1199px) {
  .apply-section .block .bg-cards {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
@media (max-width: 991px) {
  .apply-section .block .bg-cards {
    position: static;
    margin-right: -20px;
  }
}
.apply-section .block .bg-cards img {
  margin-top: auto;
}
@media (max-width: 1199px) {
  .apply-section .block .bg-cards img {
    margin-left: auto;
    max-width: 400px;
  }
}
@media (max-width: 767px) {
  .apply-section .block .bg-cards img {
    max-width: none;
    width: 375px;
    margin: 0 -20px 0 auto;
  }
}
.apply-section .block .content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 578px;
  padding-bottom: 157px;
}
@media (max-width: 1199px) {
  .apply-section .block .content {
    padding-bottom: 120px;
  }
}
@media (max-width: 991px) {
  .apply-section .block .content {
    padding-bottom: 0;
  }
}
.apply-section .block .content h2 {
  color: #FFFFFF;
  margin-bottom: 32px;
}
@media (max-width: 991px) {
  .apply-section .block .content h2 {
    margin-bottom: 16px;
  }
}
.apply-section .block .content h6 {
  color: #FCFCFD;
}
.apply-section .block .content .email-form {
  max-width: 560px;
  margin-top: 76px;
}
@media (max-width: 1199px) {
  .apply-section .block .content .email-form {
    max-width: 500px;
  }
}
@media (max-width: 991px) {
  .apply-section .block .content .email-form {
    margin-top: 48px;
  }
}

.contact-section {
  padding: 120px 0 120px;
}
@media (max-width: 1199px) {
  .contact-section {
    padding: 100px 0;
  }
}
@media (max-width: 991px) {
  .contact-section {
    padding: 90px 0;
  }
}
.contact-section .block {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px;
}
@media (max-width: 767px) {
  .contact-section .block {
    grid-template-columns: 1fr;
  }
}
.contact-section .block .item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid #EBF3FA;
  padding: 24px;
  border-radius: 16px;
}
.contact-section .block .item img {
  margin-bottom: 32px;
}
.contact-section .block .item h5 {
  margin-bottom: 8px;
}
.contact-section .block .item .p {
  margin-top: 16px;
}
.contact-section .block .item a {
  color: #327EC7;
  display: inline-block;
}

.heroSide-section {
  background-color: rgba(92, 173, 248, 0.3);
  border-radius: 0 0 40px 40px;
  z-index: 10;
}
.heroSide-section .curves {
  position: absolute;
  z-index: -2;
  top: -92px;
  bottom: 0;
  right: -150px;
  overflow: hidden;
  text-align: center;
  opacity: 0.3;
  width: 1516px;
}
@media (max-width: 991px) {
  .heroSide-section .curves {
    width: 1021px;
    right: auto;
    left: 15px;
    top: 15px;
  }
}
.heroSide-section .block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  padding: 140px 0 0;
  min-height: 800px;
}
@media (max-width: 991px) {
  .heroSide-section .block {
    padding: 136px 0 50px;
    min-height: 932px;
  }
}
.heroSide-section .block .content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 646px;
  width: 100%;
}
.heroSide-section .block .content h1 {
  margin-bottom: 24px;
}
@media (max-width: 991px) {
  .heroSide-section .block .content h1 {
    margin-bottom: 16px;
  }
}
.heroSide-section .block .content .button {
  margin-top: 48px;
  max-width: 500px;
}
@media (max-width: 767px) {
  .heroSide-section .block .content .button {
    margin-top: 40px;
  }
}
.heroSide-section .block .content .email-form {
  margin-top: 48px;
  max-width: 500px;
}
@media (max-width: 767px) {
  .heroSide-section .block .content .email-form {
    margin-top: 40px;
  }
}
.heroSide-section .block .card-images {
  position: absolute;
  right: 0;
  bottom: 0;
}
@media (max-width: 1199px) {
  .heroSide-section .block .card-images {
    transform: scale(0.7);
  }
}
@media (max-width: 991px) {
  .heroSide-section .block .card-images {
    transform: scale(1);
  }
}
.heroSide-section .block .card-images img {
  border-radius: 32px;
  box-shadow: 0 3.3060238361px 3.6230399609px 0 rgba(0, 0, 0, 0.01), 0 9.1407327652px 10.017241478px 0 rgba(0, 0, 0, 0.01), 0 22.0073623657px 24.1176586151px 0 rgba(0, 0, 0, 0.02), 0 73px 80px 0 rgba(0, 0, 0, 0.03);
  position: absolute;
  max-width: none;
}
@media (max-width: 991px) {
  .heroSide-section .block .card-images img {
    border-radius: 16px;
  }
}
.heroSide-section .block .card-images img:first-child {
  width: 645px;
  height: 407px;
  z-index: 10;
  right: 34px;
  bottom: -79px;
  transform: rotate(18deg);
}
@media (max-width: 991px) {
  .heroSide-section .block .card-images img:first-child {
    width: 350px;
    height: auto;
    right: 70px;
    bottom: -45px;
    transform: rotate(16deg);
  }
}
@media (max-width: 767px) {
  .heroSide-section .block .card-images img:first-child {
    width: 250px;
  }
}
.heroSide-section .block .card-images img:nth-child(2) {
  width: 573px;
  height: 362px;
  transform: rotate(61deg);
  transform: rotate(28deg);
  bottom: 79px;
  right: -67px;
}
@media (max-width: 991px) {
  .heroSide-section .block .card-images img:nth-child(2) {
    width: 350px;
    height: auto;
    transform: rotate(29deg);
    bottom: 6px;
    right: -43px;
  }
}
@media (max-width: 767px) {
  .heroSide-section .block .card-images img:nth-child(2) {
    width: 250px;
  }
}
.heroSide-section .block .limit-images {
  position: absolute;
  right: -226px;
  top: 4px;
  z-index: -1;
}
@media (max-width: 1199px) {
  .heroSide-section .block .limit-images {
    transform: scale(0.8);
    right: -256px;
    top: 66px;
  }
}
@media (max-width: 991px) {
  .heroSide-section .block .limit-images {
    top: auto;
    bottom: -40px;
    right: auto;
    transform: scale(1) translateX(-50%);
    left: 50%;
    margin-left: 13px;
  }
}
.heroSide-section .block .limit-images img {
  width: 986px;
  height: 986px;
}
@media (max-width: 991px) {
  .heroSide-section .block .limit-images img {
    width: 580px;
    height: auto;
    max-width: none;
  }
}
@media (max-width: 767px) {
  .heroSide-section .block .limit-images img {
    width: 444px;
    height: 444px;
  }
}
.heroSide-section .block .dynamic-images {
  position: absolute;
  right: -111px;
  top: 102px;
  z-index: -1;
}
@media (max-width: 1199px) {
  .heroSide-section .block .dynamic-images {
    transform: scale(0.8);
    right: -256px;
    top: 66px;
  }
}
@media (max-width: 991px) {
  .heroSide-section .block .dynamic-images {
    top: auto;
    bottom: -40px;
    right: auto;
    transform: scale(1) translateX(-50%);
    left: 50%;
    margin-left: -13px;
  }
}
.heroSide-section .block .dynamic-images img {
  width: 911px;
  height: 911px;
}
@media (max-width: 991px) {
  .heroSide-section .block .dynamic-images img {
    width: 580px;
    height: auto;
    max-width: none;
  }
}
@media (max-width: 767px) {
  .heroSide-section .block .dynamic-images img {
    width: 460px;
    height: 460px;
  }
}
.heroSide-section .block .finance-images {
  position: absolute;
  right: -111px;
  top: 82px;
  z-index: -1;
}
@media (max-width: 1199px) {
  .heroSide-section .block .finance-images {
    transform: scale(0.8);
    right: -256px;
    top: 66px;
  }
}
@media (max-width: 991px) {
  .heroSide-section .block .finance-images {
    top: auto;
    bottom: -60px;
    right: auto;
    transform: scale(1) translateX(-50%);
    left: 50%;
    margin-left: -13px;
  }
}
.heroSide-section .block .finance-images img {
  width: 850px;
  height: 850px;
}
@media (max-width: 991px) {
  .heroSide-section .block .finance-images img {
    width: 580px;
    height: auto;
    max-width: none;
  }
}
@media (max-width: 767px) {
  .heroSide-section .block .finance-images img {
    width: 460px;
    height: 460px;
  }
}

.work-section {
  padding: 120px 0;
}
@media (max-width: 1199px) {
  .work-section {
    padding: 100px 0;
  }
}
@media (max-width: 991px) {
  .work-section {
    padding: 90px 0;
  }
}
.work-section .block {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: #FFFFFF;
  border-radius: 32px;
}
@media (max-width: 991px) {
  .work-section .block {
    display: flex;
    flex-direction: column-reverse;
    margin: 0 -20px;
    border-radius: 0;
  }
}
.work-section .block .image {
  display: flex;
  justify-content: center;
  height: 600px;
  overflow: hidden;
}
@media (max-width: 1199px) {
  .work-section .block .image {
    height: 550px;
  }
}
@media (max-width: 991px) {
  .work-section .block .image {
    height: 444px;
  }
}
.work-section .block .image .cards {
  width: 416px;
  position: relative;
}
@media (max-width: 1199px) {
  .work-section .block .image .cards {
    width: 316px;
  }
}
.work-section .block .image .cards img {
  width: 100%;
  border-radius: 30.877px;
  height: 660px;
  margin-top: 100px;
  position: relative;
}
@media (max-width: 1199px) {
  .work-section .block .image .cards img {
    height: auto;
  }
}
@media (max-width: 991px) {
  .work-section .block .image .cards img {
    margin-top: 56px;
  }
}
.work-section .block .image .cards img.front {
  box-shadow: 0 3.1900184155px 3.4959104061px 0 rgba(0, 0, 0, 0.01), 0 8.8199920654px 9.6657447815px 0 rgba(0, 0, 0, 0.01), 0 21.2351417542px 23.2713890076px 0 rgba(0, 0, 0, 0.02), 0 70.4384918213px 77.1928634644px 0 rgba(0, 0, 0, 0.03);
  z-index: 10;
}
.work-section .block .image .cards img.back {
  left: 0;
  top: 0;
  position: absolute;
  opacity: 0.5;
  filter: blur(60px);
}
.work-section .block .content {
  display: flex;
  flex-direction: column;
  padding: 100px 32px 40px;
  justify-content: space-between;
  gap: 60px;
}
@media (max-width: 1199px) {
  .work-section .block .content {
    padding: 90px 32px 40px;
  }
}
@media (max-width: 991px) {
  .work-section .block .content {
    padding: 40px 20px;
  }
}
.work-section .block .content .collapse-item {
  flex-direction: column;
  display: flex;
}
.work-section .block .content .collapse-item button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  border: none;
  padding: 36px 16px;
  background-color: transparent;
  border-radius: 16px 16px 0 0;
  cursor: pointer;
  text-align: left;
}
@media (max-width: 991px) {
  .work-section .block .content .collapse-item button {
    padding: 32px 16px;
  }
}
.work-section .block .content .collapse-item button .collapse-arrow {
  background-color: #F2F7FA;
  padding: 8px;
  border-radius: 8px;
}
.work-section .block .content .collapse-item button.active {
  background-color: #F2F7FA;
}
.work-section .block .content .collapse-item button.active .collapse-arrow {
  background-color: #FFFFFF;
}
.work-section .block .content .collapse-item .collapse {
  margin-top: -16px;
  border-radius: 0 0 16px 16px;
}
.work-section .block .content .collapse-item .collapse p {
  padding: 0 16px 32px;
}
.work-section .block .content .collapse-item .collapse.active {
  background-color: #F2F7FA;
}

.benefit-section {
  padding: 120px 0 80px;
}
@media (max-width: 1199px) {
  .benefit-section {
    padding: 100px 0 80px;
  }
}
@media (max-width: 991px) {
  .benefit-section {
    padding: 0;
  }
}
.benefit-section .block {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 64px;
}
.benefit-section .block .h3 {
  max-width: 758px;
  width: 100%;
  text-align: center;
}
.benefit-section .block .cards {
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 1fr;
}
@media (max-width: 767px) {
  .benefit-section .block .cards {
    grid-template-columns: 1fr;
  }
}
.benefit-section .block .cards .column {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.benefit-section .block .cards .column:nth-child(2n) {
  margin-top: 64px;
}
@media (max-width: 767px) {
  .benefit-section .block .cards .column:nth-child(2n) {
    margin-top: 0;
  }
}

.text-section {
  padding: 120px 0;
}
@media (max-width: 1199px) {
  .text-section {
    padding: 100px 0;
  }
}
@media (max-width: 991px) {
  .text-section {
    padding: 90px 0;
  }
}
.text-section .block {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
@media (max-width: 991px) {
  .text-section .block {
    gap: 16px;
  }
}
.text-section .block h2 {
  max-width: 955px;
}
.text-section .block .h6 {
  max-width: 541px;
}

.big-section {
  padding: 174px 0;
}
@media (max-width: 1199px) {
  .big-section {
    padding: 100px 0;
  }
}
@media (max-width: 991px) {
  .big-section {
    padding: 90px 0;
  }
}
.big-section .block {
  display: flex;
  flex-direction: column;
}
.big-section .block h2 {
  text-align: center;
  font-size: 52px;
  line-height: 62px;
}
@media (max-width: 991px) {
  .big-section .block h2 {
    font-size: 36px;
    letter-spacing: -0.72px;
    line-height: 48px;
  }
}

.finance-section {
  padding: 120px 0;
  position: relative;
  z-index: 10;
}
@media (max-width: 1199px) {
  .finance-section {
    padding: 100px 0;
  }
}
@media (max-width: 991px) {
  .finance-section {
    padding: 90px 0;
  }
}
.finance-section .block {
  padding: 120px 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 32px;
}
@media (max-width: 1199px) {
  .finance-section .block {
    padding: 100px 40px;
  }
}
@media (max-width: 991px) {
  .finance-section .block {
    padding: 0;
    gap: 16px;
  }
}
.finance-section .block h2 {
  max-width: 1112px;
  mix-blend-mode: color-dodge;
}
.finance-section .block .h6 {
  max-width: 578px;
}
.finance-section .bg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -10;
}
@media (max-width: 991px) {
  .finance-section .bg {
    width: 335px;
  }
}
.finance-section .bg img {
  max-width: none;
}
@media (max-width: 991px) {
  .finance-section .bg img {
    width: 100%;
  }
}

.blocks-section {
  padding: 120px 0;
}
@media (max-width: 1199px) {
  .blocks-section {
    padding: 100px 0;
  }
}
@media (max-width: 991px) {
  .blocks-section {
    padding: 0;
  }
}
.blocks-section .block {
  width: 100%;
}
.blocks-section .block .cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
}
@media (max-width: 991px) {
  .blocks-section .block .cards {
    grid-template-columns: 1fr;
  }
}

.tags-section {
  padding: 80px 0;
}
@media (max-width: 991px) {
  .tags-section {
    padding: 90px 0 0;
  }
}
.tags-section.extra-spacing {
  margin-top: 75px;
}
@media (max-width: 991px) {
  .tags-section.extra-spacing {
    margin-top: 0;
  }
}
.tags-section .h2-sm {
  font-size: 60px;
  line-height: 72px;
}
@media (max-width: 991px) {
  .tags-section .h2-sm {
    font-size: 36px;
    line-height: normal;
  }
}
.tags-section .block {
  display: flex;
  flex-direction: column;
  gap: 64px;
  text-align: center;
  align-items: center;
}
@media (max-width: 991px) {
  .tags-section .block {
    gap: 32px;
  }
}
.tags-section .block .tags {
  padding: 0 130px;
}
@media (max-width: 991px) {
  .tags-section .block .tags {
    padding: 0;
  }
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
}
@media (max-width: 991px) {
  .tags {
    gap: 8px;
  }
}
.tags li {
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  padding: 12px 24px;
  text-align: center;
  border-radius: 30px;
  background-color: rgba(92, 173, 248, 0.1);
  border: 1px solid rgba(92, 173, 248, 0.2);
  color: #5CADF8;
}
@media (max-width: 991px) {
  .tags li {
    padding: 6px 12px;
    font-size: 14px;
    line-height: 14px;
  }
}

.grow-section {
  z-index: 10;
}
.grow-section .bg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 1440px;
  height: 760px;
  z-index: -10;
}
@media (max-width: 1199px) {
  .grow-section .bg {
    width: 1200px;
    height: auto;
  }
}
@media (max-width: 991px) {
  .grow-section .bg {
    width: 992px;
  }
}
@media (max-width: 767px) {
  .grow-section .bg {
    width: 555px;
  }
}
.grow-section .bg img {
  max-width: none;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.grow-section.extra-spacing {
  margin-top: 75px;
}
@media (max-width: 991px) {
  .grow-section.extra-spacing {
    margin-top: 20px;
  }
}
@media (max-width: 767px) {
  .grow-section.extra-spacing {
    margin-top: 55px;
  }
}
.grow-section .block {
  height: 760px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  gap: 24px;
  padding-bottom: 80px;
}
@media (max-width: 1199px) {
  .grow-section .block {
    height: 660px;
  }
}
@media (max-width: 991px) {
  .grow-section .block {
    height: 520px;
  }
}
@media (max-width: 767px) {
  .grow-section .block {
    height: 350px;
    padding: 0;
    gap: 12px;
  }
}
.grow-section .block h2 {
  max-width: 676px;
}
.grow-section .block p {
  max-width: 676px;
}

.feature-section {
  padding: 120px 0;
}
@media (max-width: 1199px) {
  .feature-section {
    padding: 100px 0;
  }
}
@media (max-width: 991px) {
  .feature-section {
    padding: 90px 0 0;
  }
}
.feature-section .block {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.feature-section .block .row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}
@media (max-width: 991px) {
  .feature-section .block .row {
    grid-template-columns: 1fr;
  }
}
.feature-section .block .row:first-child {
  padding-right: 116px;
}
@media (max-width: 991px) {
  .feature-section .block .row:first-child {
    padding-right: 0;
  }
}
.feature-section .block .row:nth-child(2) {
  padding-left: 116px;
  grid-template-columns: 1fr;
}
@media (max-width: 991px) {
  .feature-section .block .row:nth-child(2) {
    padding-left: 0;
  }
}

.imagine-section {
  padding: 110px 0 0;
}
@media (max-width: 991px) {
  .imagine-section {
    padding: 110px 0 0;
  }
}
.imagine-section.py-0 {
  padding: 0;
}
@media (max-width: 991px) {
  .imagine-section.py-0 {
    padding: 0 0 80px;
  }
}
.imagine-section .block {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  padding: 120px 80px;
  text-align: center;
  overflow: hidden;
  border-radius: 40px;
  z-index: 10;
}
@media (max-width: 991px) {
  .imagine-section .block {
    padding: 90px 40px;
    gap: 16px;
  }
}
@media (max-width: 767px) {
  .imagine-section .block {
    padding: 90px 20px;
  }
}
.imagine-section .block h2 {
  max-width: 852px;
}
.imagine-section .block p {
  max-width: 807px;
}
.imagine-section .block .bg {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: -10;
}
.imagine-section .block .bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.heroChecking-section {
  background-color: #5CADF8;
  border-radius: 0 0 40px 40px;
  z-index: 10;
}
.heroChecking-section .block {
  display: flex;
  align-items: center;
  position: relative;
  padding: 172px 0 72px;
  gap: 80px;
}
@media (max-width: 991px) {
  .heroChecking-section .block {
    padding: 120px 0 0;
    flex-direction: column;
    text-align: center;
    gap: 0;
  }
}
@media (max-width: 991px) {
  .heroChecking-section .block .image {
    max-width: 285px;
  }
}
.heroChecking-section .block .content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 646px;
  width: 100%;
}
@media (max-width: 991px) {
  .heroChecking-section .block .content {
    align-items: center;
    position: relative;
    top: 32px;
  }
}
.heroChecking-section .block .content h1 {
  margin-bottom: 24px;
}
@media (max-width: 991px) {
  .heroChecking-section .block .content h1 {
    margin-bottom: 16px;
  }
}
.heroChecking-section .block .content .email-form {
  margin-top: 48px;
  max-width: 500px;
}
@media (max-width: 767px) {
  .heroChecking-section .block .content .email-form {
    margin-top: 40px;
  }
}

.priority-section {
  padding: 120px 0 0;
}
@media (max-width: 1199px) {
  .priority-section {
    padding: 100px 0 0;
  }
}
@media (max-width: 991px) {
  .priority-section {
    padding: 90px 0 0;
  }
}
.priority-section .block {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 120px;
}
@media (max-width: 1199px) {
  .priority-section .block {
    gap: 100px;
  }
}
@media (max-width: 991px) {
  .priority-section .block {
    gap: 0;
  }
}
.priority-section .block .content {
  width: 100%;
  max-width: 580px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.priority-section .block .content .h3 {
  margin-bottom: 16px;
}
.priority-section .block .content .button {
  margin-top: 40px;
}
@media (max-width: 991px) {
  .priority-section .block .content .button {
    margin-top: 16px;
  }
}
.priority-section .block .image {
  position: relative;
}
@media (max-width: 767px) {
  .priority-section .block .image img {
    width: 792px;
    max-width: none;
    position: relative;
    right: -80px;
  }
}
.priority-section .block .image::after {
  z-index: -10;
  width: 1020px;
  height: 1020px;
  content: "";
  position: absolute;
  left: 50%;
  top: 50px;
  transform: translateX(-50%);
  border-radius: 1020px;
  opacity: 0.6;
  background: #4DDD96;
  filter: blur(296.5116271973px);
}

.key-section {
  padding: 120px 0;
  background-color: #FCFCFD;
}
@media (max-width: 1199px) {
  .key-section {
    padding: 100px 0;
  }
}
@media (max-width: 991px) {
  .key-section {
    padding: 90px 0;
  }
}
.key-section .h3 {
  margin-bottom: 64px;
  text-align: center;
}
@media (max-width: 991px) {
  .key-section .h3 {
    margin-bottom: 32px;
  }
}
.key-section .block {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 64px;
}
@media (max-width: 991px) {
  .key-section .block {
    gap: 32px;
  }
}
.key-section .block .row {
  grid-template-columns: repeat(3, 1fr);
  display: grid;
  gap: 16px;
}
@media (max-width: 767px) {
  .key-section .block .row {
    grid-template-columns: 1fr;
  }
}
.key-section .block .row:nth-child(3) {
  grid-template-columns: repeat(2, 1fr);
}
@media (max-width: 767px) {
  .key-section .block .row:nth-child(3) {
    grid-template-columns: 1fr;
  }
}

.heroAbout-section {
  height: 587px;
  background-color: rgba(92, 173, 248, 0.3);
  padding-top: 140px;
  border-radius: 0 0 40px 40px;
  z-index: 10;
  overflow: hidden;
}
@media (max-width: 991px) {
  .heroAbout-section {
    padding-top: 136px;
    height: auto;
    padding-bottom: 92px;
  }
}
.heroAbout-section .block {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 991px) {
  .heroAbout-section .block {
    align-items: flex-start;
  }
}
.heroAbout-section .block .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 646px;
  width: 100%;
}
@media (max-width: 991px) {
  .heroAbout-section .block .content {
    align-items: flex-start;
    text-align: left;
  }
}
@media (max-width: 767px) {
  .heroAbout-section .block .content {
    max-width: 335px;
  }
}
.heroAbout-section .block .content h1 {
  margin-bottom: 24px;
}
@media (max-width: 991px) {
  .heroAbout-section .block .content h1 {
    margin-bottom: 12px;
  }
}
.heroAbout-section .block .content .button {
  margin-top: 48px;
  max-width: 500px;
}

.understand-section {
  padding: 347px 0 283px;
  margin-top: -142px;
  margin-bottom: -60px;
}
@media (max-width: 1199px) {
  .understand-section {
    padding: 160px 0;
    margin-top: -32px;
    margin-bottom: -32px;
  }
}
@media (max-width: 991px) {
  .understand-section {
    padding: 0;
    margin: 0;
  }
}
.understand-section .bg {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: -20px;
  z-index: -10;
}
@media (max-width: 991px) {
  .understand-section .bg {
    display: flex;
    justify-content: center;
  }
}
.understand-section .bg img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
@media (max-width: 991px) {
  .understand-section .bg img {
    width: 390px;
    max-width: none;
    margin: 0 auto;
  }
}
.understand-section .block {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 22px;
  padding: 54px 22px;
  text-align: center;
  overflow: hidden;
  z-index: 10;
  max-width: 590px;
  background-color: #FFFFFF;
  width: 100%;
  margin: 0 auto;
  border-radius: 27.602px;
  box-shadow: 0 2.851682663px 3.1251316071px 0 rgba(0, 0, 0, 0.01), 0 7.88453722px 8.6405887604px 0 rgba(0, 0, 0, 0.01), 0 18.9829273224px 20.8032073975px 0 rgba(0, 0, 0, 0.02), 0 62.9677314758px 69.0057296753px 0 rgba(0, 0, 0, 0.03);
}
@media (max-width: 991px) {
  .understand-section .block {
    padding: 90px 40px;
    gap: 16px;
    background-color: transparent;
    box-shadow: none;
  }
}
@media (max-width: 767px) {
  .understand-section .block {
    padding: 90px 20px;
  }
}
.understand-section .block h2 {
  max-width: 1097px;
}
.understand-section .block p {
  max-width: 578px;
}

.empower-section {
  padding: 32px 0 0;
}
@media (max-width: 991px) {
  .empower-section {
    padding: 0;
  }
}
.empower-section .block {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  padding: 66px 80px;
  text-align: center;
  overflow: hidden;
  border-radius: 40px;
  z-index: 10;
}
@media (max-width: 991px) {
  .empower-section .block {
    padding: 90px 40px;
    gap: 16px;
  }
}
@media (max-width: 767px) {
  .empower-section .block {
    padding: 90px 20px;
  }
}
.empower-section .block h2 {
  max-width: 1097px;
}
.empower-section .block p {
  max-width: 578px;
}
.empower-section .block .bg {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: -10;
}
.empower-section .block .bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.seamless-section {
  padding: 120px 0 80px;
}
@media (max-width: 991px) {
  .seamless-section {
    padding: 90px 0;
  }
}
.seamless-section .block {
  display: flex;
}
@media (max-width: 991px) {
  .seamless-section .block {
    flex-direction: column;
  }
}
.seamless-section .block .listing {
  max-width: 620px;
  padding: 60px 42px 28px;
  display: flex;
  flex-direction: column;
  gap: 54px;
}
@media (max-width: 991px) {
  .seamless-section .block .listing {
    padding: 60px 0 28px;
  }
}
.seamless-section .block .listing li {
  display: flex;
  gap: 20px;
  align-items: flex-start;
}
.seamless-section .block .listing li .text {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.seamless-section .block .listing li .text h5 {
  font-weight: 700;
  max-width: 336px;
}
.seamless-section .block .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.seamless-section .block .content .h3 {
  margin-bottom: 16px;
}
.seamless-section .block .content .counters {
  margin-top: 122px;
  display: flex;
  align-items: center;
  gap: 40px;
}
@media (max-width: 1199px) {
  .seamless-section .block .content .counters {
    margin-top: 60px;
  }
}
@media (max-width: 991px) {
  .seamless-section .block .content .counters {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
}
.seamless-section .block .content .counters .unit {
  display: flex;
  align-items: center;
  gap: 12px;
}
.seamless-section .block .content .counters .unit .h4 {
  font-size: 42px;
  font-weight: 500;
  line-height: 62px;
}
.seamless-section .block .content .counters .unit .p {
  line-height: 22px;
  max-width: 120px;
  display: block;
}

.welcome-section {
  padding: 120px 0 0;
  position: relative;
  z-index: 0;
}
@media (max-width: 1199px) {
  .welcome-section {
    padding: 100px 0 0;
  }
}
@media (max-width: 991px) {
  .welcome-section {
    padding: 90px 0 0;
  }
}
.welcome-section::after {
  z-index: -10;
  width: 1530px;
  height: 1530px;
  background-image: url("../assets/images/svg/ring-mid.svg");
  content: "";
  position: absolute;
  right: 50%;
  top: -341px;
  margin-right: -20px;
}
.welcome-section .block {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.welcome-section .block .top {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
  margin-bottom: 106px;
}
@media (max-width: 991px) {
  .welcome-section .block .top {
    margin-bottom: 50px;
  }
}
.welcome-section .block .top .h3 {
  margin-bottom: 16px;
}
.welcome-section .block .top p {
  margin-bottom: 40px;
  max-width: 580px;
}
.welcome-section .block .bottom {
  position: relative;
  width: 1426px;
}
@media (max-width: 1199px) {
  .welcome-section .block .bottom {
    width: 120%;
  }
}
@media (max-width: 991px) {
  .welcome-section .block .bottom {
    margin-bottom: -30px;
  }
}
.welcome-section .block .bottom::after {
  z-index: -10;
  width: 1020px;
  height: 1020px;
  content: "";
  position: absolute;
  left: 50%;
  top: 50px;
  transform: translateX(-50%);
  border-radius: 1020px;
  opacity: 0.6;
  background: #4DDD96;
  filter: blur(296.5116271973px);
}
.welcome-section .block .bottom img {
  width: 1426px;
}
@media (max-width: 991px) {
  .welcome-section .block .bottom img {
    width: 928px;
    max-width: none;
  }
}

main {
  position: relative;
  overflow: hidden;
  z-index: 10;
}

section {
  position: relative;
}

.text-gray-800 {
  color: #1D2939;
}

.sm-hidden {
  display: block;
}
@media (max-width: 991px) {
  .sm-hidden {
    display: none;
  }
}

.sm {
  display: none;
}
@media (max-width: 991px) {
  .sm {
    display: block;
  }
}

.xs-hidden {
  display: block;
}
@media (max-width: 767px) {
  .xs-hidden {
    display: none;
  }
}

.xs {
  display: none;
}
@media (max-width: 767px) {
  .xs {
    display: block;
  }
}

.card-item {
  border-radius: 16px;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  padding: 20px 24px 47px;
  align-items: flex-start;
}
.card-item h4 {
  margin-top: 44px;
  margin-bottom: 16px;
}
.card-item img {
  margin-left: -12px;
}