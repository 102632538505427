//  Ulilites SCSS
.container {
    max-width: $container;
    padding-left: $gutter-x;
    padding-right: $gutter-x;
    margin: 0 auto;
    @include tablet {
        padding-left: $gutter-x-xs;
        padding-right: $gutter-x-xs;
    }
}

.container-lg {
    max-width: $container-lg;
    padding-left: $gutter-lg-x;
    padding-right: $gutter-lg-x;
    margin: 0 auto;
    @include tablet {
        padding-left: $gutter-x-xs;
        padding-right: $gutter-x-xs;
    }
}

.container-xl {
    max-width: $container-xl;
    padding-left: 0;
    padding-right: 0;
    margin: 0 auto;
}

.collapse {
    overflow: hidden;
    transition: height 300ms cubic-bezier(.49, .025, .49, 1);
    &:not(.active)  {
        height: 0;
    }
    &.sub-menu-wrapper {
        @include min-laptop {
            height: auto !important;
        }
    }
}

[data-collapse],
[data-toggler] {
    & .collapse-arrow {
        transition: 300ms all cubic-bezier(.49, .025, .49, 1);
        & img {
            transition: 300ms all cubic-bezier(.49, .025, .49, 1);
        }
    }
    &.active .collapse-arrow img {
        transform: rotate(-180deg);
    }
}
 
 
.hide-scroll {
    overflow: hidden;
    height: 100vh;
}