// Variable SCSS
// Fonts
$font-Inter: ('Inter', sans-serif);
// Colors


$gray-25: #FCFCFD;
$gray-100: #F2F4F7;
$gray-400: #98A2B3;
$gray-600: #475467;
$gray-700: #344054;
$gray-800: #1D2939;
$gray-900: #132043;


$primary-200: #F2F7FA;
$primary-300: #D5F2E4;
$primary-500: #5CADF8;
$primary-700: #5CADF8;
 

$primary-800: #5CADF8; //Brand
$primary-900: #132043;

 
$white: #FFFFFF; 
$link: #5CADF8;
// Breakpoints
$mobile-width: 767px;
$tablet-width: 991px;
$laptop-width: 1199px;
$desktop-width: 1519px;
// Container Sizes
$container: 1360px;
$container-lg: 1440px;
$container-xl: 1440px;
$gutter-x: 40px;
$gutter-lg-x: 32px;
$gutter-x-xs: 20px;
// Transition
$link-transition: 200ms all cubic-bezier(.49, .025, .49, 1);
$btn-transition: 300ms background-color cubic-bezier(.49, .025, .49, 1),
300ms color cubic-bezier(.49, .025, .49, 1),
300ms border-color cubic-bezier(.49, .025, .49, 1),
300ms opacity cubic-bezier(.49, .025, .49, 1),
300ms box-shadow cubic-bezier(.49, .025, .49, 1),
300ms stroke cubic-bezier(.49, .025, .49, 1),
300ms fill cubic-bezier(.49, .025, .49, 1);