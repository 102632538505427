// partnerships scss

.heroSide-section {
    background-color: rgba($primary-800, 30%);
    border-radius: 0 0 40px 40px;
    z-index: 10;
    & .curves {
        position: absolute;
        z-index: -2;
        top: -92px;
        bottom: 0;
        right: -150px;
        overflow: hidden;
        text-align: center;
        opacity: 0.3;
        width: 1516px;
        @include tablet {
            width: 1021px;
            right: auto;
            left: 15px;
            top: 15px;
        }
    }
    & .block {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        padding: 140px 0 0;
        min-height: 800px;
        @include tablet {
            padding: 136px 0 50px;
            min-height: 932px;
        }
        & .content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            max-width: 646px;
            width: 100%;
            & h1 {
                margin-bottom: 24px;
                @include tablet {
                    margin-bottom: 16px;
                }
            }
            & .button {
                margin-top: 48px;
                max-width: 500px;
                @include mobile {
                    margin-top: 40px;
                }
            }
            & .email-form {
                margin-top: 48px;
                max-width: 500px;
                @include mobile {
                    margin-top: 40px;
                }
            }
        }
        & .card-images {
            position: absolute;
            right: 0;
            bottom: 0;
            @include laptop {
                transform: scale(0.7);
            }
            @include tablet {
                transform: scale(1);
            }
            & img {
                border-radius: 32px;
                box-shadow: 0 3.3060238361358643px 3.623039960861206px 0 rgba(0, 0, 0, 0.01), 0 9.140732765197754px 10.017241477966309px 0 rgba(0, 0, 0, 0.01), 0 22.007362365722656px 24.117658615112305px 0 rgba(0, 0, 0, 0.02), 0 73px 80px 0 rgba(0, 0, 0, 0.03);
                position: absolute;
                max-width: none;
                @include tablet {
                    border-radius: 16px;
                }
                &:first-child {
                    width: 645px;
                    height: 407px;
                    z-index: 10;
                    right: 34px;
                    bottom: -79px;
                    transform: rotate(18deg);
                    @include tablet {
                        width: 350px;
                        height: auto;
                        right: 70px;
                        bottom: -45px;
                        transform: rotate(16deg);
                    }
                    @include mobile {
                        width: 250px;
                    }
                }
                &:nth-child(2) {
                    width: 573px;
                    height: 362px;
                    transform: rotate(61deg);
                    transform: rotate(28deg);
                    bottom: 79px;
                    right: -67px;
                    @include tablet {
                        width: 350px;
                        height: auto;
                        transform: rotate(29deg);
                        bottom: 6px;
                        right: -43px;
                    }
                    @include mobile {
                        width: 250px;
                    }
                }
            }
        }
        
        & .limit-images {
            position: absolute;
            right: -226px;
            top: 4px;
            z-index: -1;
            @include laptop {
                transform: scale(0.8);
                right: -256px;
                top: 66px;
            }
            @include tablet {
                top: auto;
                bottom: -40px;
                right: auto;
                transform: scale(1) translateX(-50%);
                left: 50%;
                margin-left: 13px;
            }
            & img {
                width: 986px;
                height: 986px;
                @include tablet {
                    width: 580px;
                    height: auto;
                    max-width: none;
                }
                @include mobile {
                    width: 444px;
                    height: 444px;
                }
            }
        }
        & .dynamic-images {
            position: absolute;
            right: -111px;
            top: 102px;
            z-index: -1;
            @include laptop {
                transform: scale(0.8);
                right: -256px;
                top: 66px;
            }
            @include tablet {
                top: auto;
                bottom: -40px;
                right: auto;
                transform: scale(1) translateX(-50%);
                left: 50%;
                margin-left: -13px;
            }
            & img {
                width: 911px;
                height: 911px;
                @include tablet {
                    width: 580px;
                    height: auto;
                    max-width: none;
                }
                @include mobile {
                    width: 460px;
                    height: 460px;
                }
            }
        }
        & .finance-images {
            position: absolute;
            right: -111px;
            top: 82px;
            z-index: -1;
            @include laptop {
                transform: scale(0.8);
                right: -256px;
                top: 66px;
            }
            @include tablet {
                top: auto;
                bottom: -60px;
                right: auto;
                transform: scale(1) translateX(-50%);
                left: 50%;
                margin-left: -13px;
            }
            & img {
                width: 850px;
                height: 850px;
                @include tablet {
                    width: 580px;
                    height: auto;
                    max-width: none;
                }
                @include mobile {
                    width: 460px;
                    height: 460px;
                }
            }
        }

    }
}

.work-section {
    padding: 120px 0;
    @include laptop {
        padding: 100px 0;
    }
    @include tablet {
        padding: 90px 0;
    }
    & .block {
        display: grid;
        grid-template-columns: 1fr 1fr;
        background-color: $white;
        border-radius: 32px;
        @include tablet {
            display: flex;
            flex-direction: column-reverse;
            margin: 0 -20px;
            border-radius: 0;
        }
        & .image {
            display: flex;
            justify-content: center;
            height: 600px;
            overflow: hidden;
            @include laptop {
                height: 550px;
            }
            @include tablet {
                height: 444px;
            }
            & .cards {
                width: 416px;
                position: relative;
                @include laptop {
                    width: 316px;
                }
                & img {
                    width: 100%;
                    border-radius: 30.877px;
                    height: 660px;
                    margin-top: 100px;
                    position: relative;
                    @include laptop {
                        height: auto;
                    }
                    @include tablet {
                        margin-top: 56px;
                    }
                    &.front {
                        box-shadow: 0 3.19001841545105px 3.495910406112671px 0 rgba(0, 0, 0, 0.01), 0 8.819992065429688px 9.66574478149414px 0 rgba(0, 0, 0, 0.01), 0 21.23514175415039px 23.27138900756836px 0 rgba(0, 0, 0, 0.02), 0 70.43849182128906px 77.19286346435547px 0 rgba(0, 0, 0, 0.03);
                        z-index: 10;
                    }
                    &.back {
                        left: 0;
                        top: 0;
                        position: absolute;
                        opacity: 0.5;
                        filter: blur(60px);
                    }
                }
            }
        }
        & .content {
            display: flex;
            flex-direction: column;
            padding: 100px 32px 40px;
            justify-content: space-between;
            gap: 60px;
            @include laptop {
                padding: 90px 32px 40px;
            }
            @include tablet {
                padding: 40px 20px;
            }
            & .collapse-item {
                flex-direction: column;
                display: flex;
                & button {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0;
                    border: none;
                    padding: 36px 16px;
                    background-color: transparent;
                    border-radius: 16px 16px 0 0;
                    cursor: pointer;
                    text-align: left;
                    @include tablet {
                        padding: 32px 16px;
                    }
                    & .collapse-arrow {
                        background-color: $primary-200;
                        padding: 8px;
                        border-radius: 8px;
                    }
                    &.active {
                        background-color: $primary-200;
                        & .collapse-arrow {
                            background-color: $white;
                        }
                    }
                }
                & .collapse {
                    margin-top: -16px;
                    border-radius: 0 0 16px 16px;
                    & p {
                        padding: 0 16px 32px;
                    }
                    &.active {
                        background-color: $primary-200;
                    }
                }
            }
        }
    }
}

.benefit-section {
    padding: 120px 0 80px;
    @include laptop {
        padding: 100px 0 80px;
    }
    @include tablet {
        padding: 0;
    }
    & .block {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 64px;
        & .h3 {
            max-width: 758px;
            width: 100%;
            text-align: center;
        }
        & .cards {
            display: grid;
            gap: 16px;
            grid-template-columns: 1fr 1fr;
            @include mobile {
                grid-template-columns: 1fr;
            }
            & .column {
                display: flex;
                flex-direction: column;
                gap: 16px;
                &:nth-child(2n) {
                    margin-top: 64px;
                    @include mobile {
                        margin-top: 0;
                    }
                }
            }
        }
    }
}

.text-section {
    padding: 120px 0;
    @include laptop {
        padding: 100px 0;
    }
    @include tablet {
        padding: 90px 0;
    }
    & .block {
        display: flex;
        flex-direction: column;
        gap: 32px;
        @include tablet {
            gap: 16px;
        }
        & h2 {
            max-width: 955px;
        }
        & .h6 {
            max-width: 541px;
        }
    }
}

.big-section {
    padding: 174px 0;
    @include laptop {
        padding: 100px 0;
    }
    @include tablet {
        padding: 90px 0;
    }
    & .block {
        display: flex;
        flex-direction: column;
        & h2 {
            text-align: center;
            font-size: 52px;
            line-height: 62px;
            @include tablet {
                font-size: 36px;
                letter-spacing: -.72px;
                line-height: 48px;
            }
        }
    }
}

.finance-section {
    padding: 120px 0;
    position: relative;
    z-index: 10;
    @include laptop {
        padding: 100px 0;
    }
    @include tablet {
        padding: 90px 0;
    }
    & .block {
        padding: 120px 48px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 32px;
        @include laptop {
            padding: 100px 40px;
        }
        @include tablet {
            padding: 0;
            gap: 16px;
        }
        & h2 {
            max-width: 1112px;
            mix-blend-mode: color-dodge;
        }
        & .h6 {
            max-width: 578px;
        }
    }
    & .bg {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        z-index: -10;
        @include tablet {
            width: 335px;
        }
        & img {
            max-width: none;
            @include tablet {
                width: 100%;
            }
        }
    }
}

.blocks-section {
    padding: 120px 0;
    @include laptop {
        padding: 100px 0;
    }
    @include tablet {
        padding: 0;
    }
    & .block {
        width: 100%;
        & .cards {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 16px;
            @include tablet {
                grid-template-columns: 1fr;
            }
        }
    }
}
